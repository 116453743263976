import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import "../../../CSS/Client.css";

import ClientDocViewerList from "../DocViewerList/ClientDocViewerList";
import HomeSafetyViewPage from "./HomeSafetyViewPage";

const HomeSafetyTab = observer(() => {
  const [value, setValue] = React.useState("2");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="clientDoctorMainContaner">
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              variant="scrollable"
            >
              {/* <Tab className="profileEachMenu" label="Home Safety" value="1" /> */}
              <Tab
                className="profileEachMenu"
                label="Upload Home Safety Records File"
                value="2"
              />
            </TabList>
          </Box>
          {/* <TabPanel className="profileEachTabPanel" value="1">
            <HomeSafetyViewPage />
          </TabPanel> */}
          <TabPanel className="profileEachTabPanel" value="2">
            <ClientDocViewerList DocType="HOME_SAFETY" />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
});

export default HomeSafetyTab;
