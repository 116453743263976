import { observer } from "mobx-react";
import React, { useMemo, useState } from "react";
import FormikController from "../../../components/FormikController/FormikController";
import {
  dateCellRenderer,
  fullNameCellRenderer,
  useOnGridReady,
} from "../../../Utils/AgGridUtils";
import { ClientURL } from "../../../Url/Url";
import { useFormik } from "formik";
import AgencyStore from "../../../Store/AgencyStore";
import { YupValidation } from "../../../Constants/ValidationConstants";
import axios from "axios";
import * as Yup from "yup";
import ClientStore from "../../../Store/ClientStore";
import { useParams } from "react-router-dom";
import {
  centerStyles,
  leftStyles,
  rightStyles,
} from "../../../Constants/Constants";
import CareGiverStore from "../../../Store/CareGiverStore";
import UpdateCaseNotePopUp from "./UpdateCaseNotePopUp";
import Icon from "../../../components/Icons/Icon";
import UpdateDigonisisPopUp from "./UpdateDigonisisPopUp";

const ClientProfileDigonisis = observer(() => {
  const { clientId } = useParams();

  const [onGridReady, onFirstDataRendered] = useOnGridReady(
    ClientStore,
    "clientAgGrid"
  );

  const formik = useFormik({
    initialValues: {
      caseNote: "",
    },

    validationSchema: Yup.object({
      caseNote: YupValidation.caseNote,
    }),
    onSubmit: (values) => {
      saveClientProfileCaseNote(values);
    },
  });

  const handleSaveButtonClick = () => {
    ClientStore.setIndividualDocterCaseNoteData({});
  };
  const saveClientProfileCaseNote = (values) => {
    AgencyStore.setIsLoading(true);

    const params = {
      clientProfileId: clientId,
      caseNote: values.caseNote,
    };
    axios
      .post(ClientURL.SAVE_CLIENT_CASENOTE, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          ClientStore.getClientProfileCaseNotes(clientId);
          formik.resetForm();
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("success", "successfully added");
        } else if (res?.data?.status == "failed") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", res?.data?.message);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to save Client Case note");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to save Client Case note");
      });
  };

  const columnDefs = useMemo(
    () => [
      {
        headerName: "SN",
        width: 100,
        filter: true,
        cellStyle: (params) => {
          return leftStyles;
        },
        cellRenderer: (params) => params.node.rowIndex + 1,
      },
      {
        field: "caseNote",
        headerName: "Case Note",
        filter: true,
        cellStyle: (params) => {
          return leftStyles;
        },
        cellRenderer: fullNameCellRenderer,
      },
      {
        field: "insertDate",
        headerName: "Created Date",
        filter: true,
        headerClass: "center-header",
        cellStyle: (params) => {
          return centerStyles;
        },
        cellRenderer: dateCellRenderer,
      },

      {
        field: "lastUpdatedDate",
        headerName: "Last Updated Date",
        filter: true,
        headerClass: "center-header",
        cellStyle: (params) => {
          return centerStyles;
        },
        cellRenderer: dateCellRenderer,
      },
      {
        field: "update",
        headerName: "Action",
        sortable: false,
        headerClass: "center-header",
        cellStyle: (params) => {
          return centerStyles;
        },
        cellRenderer: (params) => {
          const handleButtonClick = () => {
            ClientStore.setIndividualDocterCaseNoteData(params.data);
          };
          return (
            <UpdateCaseNotePopUp
              type="ClientCaseNote"
              onConfirm={() => {}}
              clickHTML={
                <div
                  className="manageClick"
                  onClick={() => {
                    ClientStore.setCaseNotestatus("update");
                    handleButtonClick();
                  }}
                >
                  Update
                </div>
              }
            />
          );
        },
      },
    ],
    []
  );
  if (ClientStore.clientCaseNoteAllData.resultSet.length > 0) {
    CareGiverStore.setTableHeight(
      ClientStore.clientCaseNoteAllData.resultSet.length
    );
  }
  return (
    <div>
      <div className="CDCNunderlineClickBtnContainer">
        <UpdateDigonisisPopUp
          type="ClientCaseNote"
          clickHTML={
            <div
              className="underlineClick"
              onClick={() => {
                ClientStore.setCaseNotestatus("save");
                handleSaveButtonClick();
              }}
            >
              <Icon iconName="circleAdd" />
              Add Digonisis
            </div>
          }
        />
      </div>

      <FormikController
        rowData={ClientStore.clientCaseNoteAllData.resultSet}
        control="agGrid"
        columnDefs={columnDefs}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
      />
    </div>
  );
});

export default ClientProfileDigonisis;
