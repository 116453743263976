import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import "../../../CSS/Client.css";
import AddClient from "./AddClient";
import ClientProfileCaseNote from "./ClientProfileCaseNote";
import ClientProfileDigonisis from "./ClientProfileDigonisis";

const ClientProfileNotesMain = observer(() => {
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <div className="clientDoctorMainContaner">
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                variant="scrollable"
              >
                <Tab className="profileEachMenu" label="Profile" value="1" />
                {/* {ClientStore?.docterInfoAllData?.doctorInfo?.id && ( */}
                <Tab className="profileEachMenu" label="Case Note" value="2" />
                {/* )} */}
                {/* <Tab className="profileEachMenu" label="Digonisis" value="3" /> */}
              </TabList>
            </Box>
            <TabPanel className="profileEachTabPanel" value="1">
              <AddClient />
            </TabPanel>
            {/* {ClientStore?.docterInfoAllData?.doctorInfo?.id && ( */}
            <TabPanel className="profileEachTabPanel" value="2">
              <ClientProfileCaseNote />
            </TabPanel>
            {/* )} */}
            <TabPanel className="profileEachTabPanel" value="3">
              <ClientProfileDigonisis />
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </>
  );
});

export default ClientProfileNotesMain;
