import React, { useEffect, useRef, useState } from "react";
import ClientStore from "../../../../Store/ClientStore";
import { observer } from "mobx-react";
import { Avatar, Tooltip } from "@mui/material";
import DocterPopUp from "./DocterPopUp";
import { RxUpdate } from "react-icons/rx";
import { SlPrinter } from "react-icons/sl";
import { ImFilePdf } from "react-icons/im";
import ClientDocterInfo from "../../../../components/FormsInvoice/ClientDocterInfo/ClientDocterInfo";
import { handleGeneratePdf } from "../../../../Utils/Utils";
import { useParams } from "react-router-dom";
import AgencyStore from "../../../../Store/AgencyStore";
import axios from "axios";
import { ClientURL } from "../../../../Url/Url";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaRegEye } from "react-icons/fa";
import { FaRegPenToSquare } from "react-icons/fa6";

import ConfirmPopUp from "../../../../components/ConfirmPopUp/ConfirmPopUp";
import Icon from "../../../../components/Icons/Icon";
import { openPdf } from "../../PdfViewer/CommonUtilsPdf";
import DoctorInformation from "../../PdfViewer/DoctorInformation";
function stringToColor(string) {
  let hash = 0;
  for (let i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (let i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}`,
  };
}

const DocterList = () => {
  const { clientId } = useParams();

  const contentElementRef = useRef();
  const handlePdfGeneration = (openInNewWindow) => {
    // handleGeneratePdf({
    //   contentElement: contentElementRef.current,
    //   filename: "Docter Information.pdf",
    //   headerText: "",
    //   headerAlign: "center",
    //   textAlign: "left",
    //   imageData: "Image Data",
    //   columns: null,
    //   rows: null,
    //   openInNewWindow: openInNewWindow,
    // });
  };

  const [allDocterlist, setAllDocterlist] = useState([]);
  const [isClientDocUpdate, setClientDocUpdate] = useState(true);

  const handleClientDocterUpdate = (value) => {
    setClientDocUpdate(value);
  };

  const deleteDoctorInfo = (values) => {
    AgencyStore.setIsLoading(true);
    const params = {
      clientProfileId: clientId,
      docInfoId: ClientStore.individualDocterInfoData.id,
    };

    axios

      .post(ClientURL.DELETE_DOCTOR_INFO, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          AgencyStore.setIsLoading(false);
          ClientStore.getDoctorInfo(clientId);
          ClientStore.setIndividualDocterInfoData({});
          AgencyStore.showAlert("success", "successfully Deleted");
        } else if (res?.data?.status == "failed") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", res?.data?.message);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to Deleted");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to Deleted");
      });
  };

  useEffect(() => {
    setAllDocterlist(ClientStore?.docterInfoAllData?.resultSet);
  }, [ClientStore?.docterInfoAllData]);
  return (
    <div>
      {isClientDocUpdate ? (
        <>
          <div className="DoctorListContainer">
            <DocterPopUp
              type="save"
              clickHTML={
                <div className="underlineClick">
                  <Icon iconName="circleAdd" />
                  Add Doctor
                </div>
              }
            />
          </div>
          <div className="DocterInfoHeaderContainer">
            <div className="headerItem">SN</div>
            <div className="headerItem">Name</div>
            <div className="headerItem">Address</div>
            <div className="headerItem">Phone</div>
            <div className="headerItem flexLeft">Action</div>
          </div>
          {allDocterlist.length === 0 ? (
            <div className="noRecordsMessage">No records available</div>
          ) : (
            allDocterlist.map((item, index) => (
              <div className="DocterInfoGridContainer" key={index}>
                <div className="gridItem">{index + 1}</div>
                <div className="gridItem">
                  <div className="avatarNameContainer">
                    <Avatar {...stringAvatar(`${item.doctor}`)} />
                    <div className="gridItemNameEmail">{item.doctor}</div>
                  </div>
                </div>
                <div className="gridItem">{item.address}</div>
                <div className="gridItem">{item.phoneNo}</div>

                <div className="gridItem flexLeft">
                  <DocterPopUp
                    type="update"
                    clickHTML={
                      <Tooltip title="Edit">
                        <div
                          className="iconBox updateIcon "
                          onClick={() => {
                            ClientStore.setIndividualDocterInfoData(item);
                          }}
                        >
                          <FaRegPenToSquare />
                        </div>
                      </Tooltip>
                    }
                  />

                  <ConfirmPopUp
                    buttonLabel="Delete"
                    title="Delete Schedule"
                    content=" Please confirm your decision below."
                    onConfirm={() => {
                      ClientStore.setIndividualDocterInfoData(item);
                      deleteDoctorInfo();
                    }}
                    clickHTML={
                      <Tooltip title="Delete">
                        <div className="iconBox actionMarginLeft deleteIcon">
                          <RiDeleteBin6Line />
                        </div>
                      </Tooltip>
                    }
                  />

                  <Tooltip title="View">
                    <div
                      className="iconBox actionMarginLeft viewIcon "
                      onClick={() => {
                        ClientStore.setIndividualDocterInfoData(item);

                        handleClientDocterUpdate(false);
                      }}
                    >
                      {/* View */}
                      <FaRegEye />
                    </div>
                  </Tooltip>
                </div>
              </div>
            ))
          )}
        </>
      ) : (
        <>
          <div>
            <div className="underlineClickBtnContainer">
              <div
                className="underlineClick"
                onClick={() => {
                  handleClientDocterUpdate(true);
                }}
              >
                <RxUpdate /> List
              </div>
              {/* <div
                className="underlineClick"
                // onClick={() => {
                //   handleClientDocterUpdate(true);
                // }}
              >
                <RxUpdate /> Update
              </div> */}
              {/* <div
                className="underlineClick"
                onClick={() => {
                  handlePdfGeneration(true);
                }}
              >
                <SlPrinter />
                Print
              </div> */}

              <div
                className="underlineClick"
                onClick={() => {
                  openPdf(<DoctorInformation />);
                }}
              >
                <SlPrinter />
                Print
              </div>
              <div
                className="underlineClick"
                onClick={() => {
                  handlePdfGeneration(false);
                }}
              >
                <ImFilePdf />
                <spam className="underlineClickTitle"> Download</spam>
              </div>
            </div>
            <ClientDocterInfo contentElementRef={contentElementRef} />
          </div>
        </>
      )}
    </div>
  );
};

export default observer(DocterList);
