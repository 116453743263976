import { pdf, StyleSheet, Text } from "@react-pdf/renderer";
export const openPdf = async (children) => {
  const blob = await pdf(children).toBlob();
  const pdfUrl = URL.createObjectURL(blob);
  window.open(pdfUrl, "_blank");
};

export const styles = StyleSheet.create({
  page: {
    paddingTop: 40,
    paddingLeft: 40,
    paddingRight: 40,
    lineHeight: 1.5,
    paddingBottom: 40,
    flexDirection: "column",
  },
  pageNumber: {
    textAlign: "center",
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
    color: "#2f2b3dc7",
    fontSize: "7px",
  },

  titleContainer: { marginTop: 24, textAlign: "center" },
  tableItem: { fontWeight: "bold", fontSize: "11px" },

  reportTitle: {
    fontSize: 16,
    textAlign: "center",
    color: "#333",
  },
  underline: { textDecoration: "underline", color: "#000" },

  theader: {
    flexDirection: "row",
    textAlign: "center",
  },
  theaders: {
    color: "#fff",
    flex: 1,
    backgroundColor: "#2f2b3dc7",
    fontWeight: 800,
    paddingTop: "10px",
    paddingBottom: "8px",
    marginTop: 10,
    fontWeight: "bold",
  },

  tbody: {
    flexWrap: "wrap",
    flex: 1,
    fontWeight: 800,
    paddingTop: "10px",
    fontSize: 10,
    paddingBottom: "8px",
    border: 0.2,
    letterSpacing: 0.2,
    backgroundColor: "#FFF",
  },

  //doctor list info table
  doctorTitle: { marginTop: 24, textAlign: "center" },

  doctorContainer: {
    borderTop: "1px solid #000",
    // borderLeft: "1px solid #000",
    marginTop: 20,
  },
  doctorInfo: {
    flexDirection: "row",
    borderBottom: "1px solid #000",
    fontSize: 11,
    alignItems: "center",
    borderLeft: "1px solid #000",
  },
  infoTitle: {
    flex: 1,
    padding: "11px 20px 10px 20px",
    borderRight: "1px solid #000",
  },
  infoItem: {
    flex: 2,
    padding: "11px 20px 10px 20px",
    borderRight: "1px solid #000",
  },
});
