import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Badge, Box, Tab } from "@mui/material";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import PageTitle from "../../PageTitle";
import NotificationStore from "../../../Store/NotificationStore";
import NotificationClientTable from "./NotificationClientTable";
import NotificationCareGiverTable from "./NotificationCareGiverTable";
import NotificationAdminTable from "./NotificationAdminTable";
import NotificationCoordinatorTable from "./NotificationCoordinatorTable";
import NotificationNurseTable from "./NotificationNurseTable";
import SearchBox from "../../../components/Search/SearchBox";
import useSearchData from "../../../components/useSearchData";
import { employeeName } from "../../../Constants/Constants";
import Icon from "../../../components/Icons/Icon";

const Notification = () => {
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [handleSearchChange, handleActiveCheckBox] = useSearchData();
  useEffect(() => {
    NotificationStore.getEmpClientUnseenNotificationCount();

    NotificationStore.getClientNotification();
    NotificationStore.getEmployeeNotification("CareGiver");
    NotificationStore.getEmployeeNotification("AdminEmployee");
    NotificationStore.getEmployeeNotification("CommunityCoordinator");
    NotificationStore.getEmployeeNotification("RN");
  }, []);

  return (
    <div>
      <div className="pageHeaderContainer">
        <PageTitle
          icon={<Icon iconName="menu" />}
          headerTitle="Notification"
          subHeaderTitle="Navigation/Notification"
        />
      </div>
      <div className="profileMainContainer">
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box
              className="profileTabsMenu"
              sx={{ borderBottom: 1, borderColor: "divider" }}
            >
              <TabList
                className="profileTabsMenu"
                onChange={handleChange}
                aria-label="lab API tabs example"
                variant="scrollable"
              >
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Badge
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      badgeContent={
                        NotificationStore?.notificationUnseenCount
                          ?.notificationCountOfClient
                      }
                      color="error"
                    >
                      <Icon
                        iconName="client"
                        className="profileEeachMenuIcon"
                      />
                    </Badge>
                  }
                  label={employeeName.client}
                  value="1"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Badge
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      badgeContent={
                        NotificationStore?.notificationUnseenCount
                          ?.empCategoryCareGiver
                      }
                      color="error"
                    >
                      <Icon
                        iconName="careGiver"
                        className="profileEeachMenuIcon"
                      />
                    </Badge>
                  }
                  label="Personal Care Aid"
                  value="2"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Badge
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      badgeContent={
                        NotificationStore?.notificationUnseenCount
                          ?.empCategoryAdminEmployee
                      }
                      color="error"
                    >
                      <Icon iconName="admin" className="profileEeachMenuIcon" />
                    </Badge>
                  }
                  label={employeeName.adminEmployee}
                  value="3"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Badge
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      badgeContent={
                        NotificationStore?.notificationUnseenCount
                          ?.empCategoryCommunityCoordinator
                      }
                      color="error"
                    >
                      <Icon
                        iconName="coordinator"
                        className="profileEeachMenuIcon"
                      />
                    </Badge>
                  }
                  label={employeeName.communityCordinator}
                  value="4"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Badge
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      badgeContent={
                        NotificationStore?.notificationUnseenCount
                          ?.empCategoryRN
                      }
                      color="error"
                    >
                      <Icon iconName="nurse" className="profileEeachMenuIcon" />
                    </Badge>
                  }
                  label={employeeName.nurse}
                  value="5"
                />
              </TabList>
            </Box>
            <TabPanel className="profileEachTabPanel" value="1">
              <div className="actionButtonSearchMainContainer">
                <div className="searchMainCotainer">
                  <div className="searchContainer">
                    <SearchBox
                      handleChange={(e) => {
                        handleSearchChange(e, "NotificationClient");
                      }}
                    />
                  </div>
                  <div className="activeAddButtonContainer">
                    <div>
                      <input
                        id="careGiver_active"
                        type="checkbox"
                        onChange={(e) => {
                          handleActiveCheckBox(
                            e,
                            "false",
                            "NotificationClient"
                          );
                        }}
                      />
                      <span className="checkBoxTitle">Unseen</span>
                    </div>
                  </div>
                </div>
              </div>
              <NotificationClientTable />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="2">
              <div className="actionButtonSearchMainContainer">
                <div className="searchMainCotainer">
                  <div className="searchContainer">
                    <SearchBox
                      handleChange={(e) => {
                        handleSearchChange(e, "CareGiverNotification");
                      }}
                    />
                  </div>
                  <div className="activeAddButtonContainer">
                    <div>
                      <input
                        id="careGiver_active"
                        type="checkbox"
                        onChange={(e) => {
                          handleActiveCheckBox(
                            e,
                            "false",
                            "CareGiverNotification"
                          );
                        }}
                      />
                      <span className="checkBoxTitle">Unseen</span>
                    </div>
                  </div>
                </div>
              </div>
              <NotificationCareGiverTable />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="3">
              <div className="actionButtonSearchMainContainer">
                <div className="searchMainCotainer">
                  <div className="searchContainer">
                    <SearchBox
                      handleChange={(e) => {
                        handleSearchChange(e, "AdminNotification");
                      }}
                    />
                  </div>
                  <div className="activeAddButtonContainer">
                    <div>
                      <input
                        id="careGiver_active"
                        type="checkbox"
                        onChange={(e) => {
                          handleActiveCheckBox(e, "false", "AdminNotification");
                        }}
                      />
                      <span className="checkBoxTitle">Unseen</span>
                    </div>
                  </div>
                </div>
              </div>
              <NotificationAdminTable />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="4">
              <div className="actionButtonSearchMainContainer">
                <div className="searchMainCotainer">
                  <div className="searchContainer">
                    <SearchBox
                      handleChange={(e) => {
                        handleSearchChange(e, "CoordinatorNotification");
                      }}
                    />
                  </div>
                  <div className="activeAddButtonContainer">
                    <div>
                      <input
                        id="careGiver_active"
                        type="checkbox"
                        onChange={(e) => {
                          handleActiveCheckBox(
                            e,
                            "false",
                            "CoordinatorNotification"
                          );
                        }}
                      />
                      <span className="checkBoxTitle">Unseen</span>
                    </div>
                  </div>
                </div>
              </div>
              <NotificationCoordinatorTable />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="5">
              <div className="actionButtonSearchMainContainer">
                <div className="searchMainCotainer">
                  <div className="searchContainer">
                    <SearchBox
                      handleChange={(e) => {
                        handleSearchChange(e, "NurseNotification");
                      }}
                    />
                  </div>
                  <div className="activeAddButtonContainer">
                    <div>
                      <input
                        id="careGiver_active"
                        type="checkbox"
                        onChange={(e) => {
                          handleActiveCheckBox(e, "false", "NurseNotification");
                        }}
                      />
                      <span className="checkBoxTitle">Unseen</span>
                    </div>
                  </div>
                </div>
              </div>
              <NotificationNurseTable />
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </div>
  );
};

export default React.memo(observer(Notification));
